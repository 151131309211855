import Splitting from "splitting";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

document.addEventListener("DOMContentLoaded", function () {
	/*
	----------------------
	Banner intro
	----------------------
	*/
	const target = document.querySelector(".main-banner .caption h1 strong");
	const results = Splitting({ target: target, by: "words" });

	const bannerIntro = gsap.timeline({ paused: true });

	bannerIntro
		.set(".main-banner .caption h1", {
			opacity: 1,
		})
		.from(".main-banner .caption h1 .word", {
			opacity: 0,
			stagger: 0.15,
			duration: 3,
		})
		.to(
			".main-banner .caption p",
			{
				opacity: 1,
				duration: 1,
			},
			"-=1.25"
		)
		.to(
			".main-banner .back",
			{
				opacity: 1,
				duration: 3,
			},
			"-=2.5"
		)
		.to(
			".main-banner .caption .btn",
			{
				opacity: 1,
				duration: 1,
			},
			"-=1.5"
		);
	setTimeout(() => {
		bannerIntro.play();
	}, 300);
	/*
		Busca hielo
	*/
	gsap.registerPlugin(ScrollTrigger);

	const wave = document.querySelector(".np-hielo .wave");
	const vasos = document.querySelector(".np-hielo .splash .vasos");
	const fl1 = document.querySelector(".np-hielo .splash .flechita1");
	const fl2 = document.querySelector(".np-hielo .splash .flechita2");
	const hs = document.querySelector(".np-hielo .block .hs");
	const hsg = document.querySelector(".np-hielo .block .hsg");

	gsap.set(wave, { y: +300 });
	gsap.set(vasos, { y: 100, scale: 0.85, opacity: 0 });
	gsap.set(fl1, { x: -50, opacity: 0 });
	gsap.set(fl2, { x: 50, opacity: 0 });
	gsap.set(hs, { y: 100, opacity: 0 });
	gsap.set(hsg, { y: 100, opacity: 0 });

	const npTimeline = gsap.timeline({ paused: true });

	npTimeline
		.to(wave, { y: 0, ease: " back.out(1)", duration: 1 })
		.to(
			vasos,
			{
				y: 0,
				scale: 1,
				ease: "power4.out",
				opacity: 1,
				duration: 1,
			},
			"-=.5"
		)
		.to(fl1, {
			x: 0,
			ease: "power3.out",
			opacity: 1,
			duration: 1,
		})
		.to(
			fl2,
			{
				x: 0,
				ease: "power3.out",
				opacity: 1,
				duration: 1,
			},
			"<"
		)
		.to(
			hs,
			{
				y: 0,
				ease: "power4.out",
				opacity: 1,
				duration: 1,
			},
			"<"
		)
		.to(
			hsg,
			{
				y: 0,
				ease: "power4.out",
				opacity: 1,
				duration: 1,
			},
			"<"
		);

	ScrollTrigger.create({
		trigger: ".np-hielo",
		start: "top 50%",
		end: "bottom bottom",
		// markers: true,
		onEnter: () => {
			npTimeline.play();
		},
		onLeaveBack: () => {
			npTimeline.timeScale(2).reverse();
		},
	});
});
